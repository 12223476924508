<script>
import { mapState } from 'vuex';
import filters from '@/lib/agreement/filters';
import generateSummaryDocument from '@/lib/agreement/generateSummary';

export default {
	computed: {
		...mapState(['agreement']),
		partner1Name() {
			return filters.firstLastName(this.agreement.partner1);
		},
		partner2Name() {
			return filters.firstLastName(this.agreement.partner2);
		},
		getQueryParams(){
			return this.$route.query;
		}
	},
	props: {
		filename: { default: () => '', type: String },
		summaryContents: { default: () => null, type: String },
		showWatermark: { default: () => false, type: Boolean },
	},
	asyncComputed: {
		contents: {
			watch: ['agreement'],
			async get() {
				this.error = false;
				if (!this.agreement.id) return;
				try {
					return this.generateSummaryDocument();
				} catch (error) {
					if (process.env.NODE_ENV === 'development') {
						console.error(error);
					}
					this.error = true;
				}
			},
		},
	},
	methods: {
		generateSummaryDocument() {
			return generateSummaryDocument({
				agreementData: this.agreement,
			});
		},
	}
};
</script>

<template>
	<div id="summary">
		<div v-if="summaryContents || contents" class="summary">
			
			<div class="header-container">
                <h3 class="filename">{{ filename }}</h3>
                <img src="@/assets/Divii-Logo.png" alt="divii logo" width="100" height="33" class="logo">
            </div>
			<div v-if="summaryContents" v-html="summaryContents" />
			<div v-else-if="contents" v-html="contents" />
		
			
		</div>
		<div
			v-else
			class="
				text-center
				w-100
				d-flex
				justify-content-center
				align-items-center
			"
			style="height: 500px"
		>
			<b-spinner
				style="width: 3rem; height: 3rem"
				variant="primary"
				label="Loading..."
			/>
		</div>
	</div>
</template>

<style>
@import '../../assets/css/printstyles.css';
@import '../../assets/css/summaryStyles.css';

#downloadLink {
	display: none;
}

.missing {
	background: yellow;
}
.header-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-bottom: 20px;
}
.filename {
	margin: 0;
	padding-right: 20px;
}
.logo {
	flex-shrink: 0;
}
</style>
